const travelData = [
  {
    id: 0,
    country: 'India',
    location: 'Indore',
    flag: '🇮🇳',
    img:
      'https://content.r9cdn.net/rimg/dimg/81/27/f9da72b6-city-18952-169edddbd24.jpg?width=1366&height=768&xhint=2118&yhint=1521&crop=true', // India
  },
  {
    id: 1,
    country: 'Canada',
    location: 'Toronto',
    flag: '🇨🇦',
    img: 'https://canadiangeographic.ca/wp-content/uploads/2022/10/amazing-toronto-skyline-1.jpg', // Canada
  },
  {
    id: 2,
    country: 'Italy',
    location: 'Venice',
    flag: '🇮🇹',
    img: 'https://venicelover.com/images/venice.jpg',
  },
  {
    id: 3,
    country: 'Switzerland',
    location: 'Lucerne',
    flag: '🇨🇭',
    img:
      'https://images.unsplash.com/photo-1568574728383-06fca083883d?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D', // Switzerland
  },
  {
    id: 4,
    country: 'France',
    location: 'Paris',
    flag: '🇫🇷',
    img:
      'https://dynamic-media-cdn.tripadvisor.com/media/photo-o/1b/4b/59/86/caption.jpg?w=1400&h=1400&s=1', // France
  },
  {
    id: 5,
    country: 'Germany',
    location: 'Waldorf',
    flag: '🇩🇪',
    img:
      'https://media.istockphoto.com/id/486585530/photo/berlin-skyline-with-spree-river-at-sunset-germany.jpg?s=612x612&w=0&k=20&c=COfGf3zm3CDWUUKv8Kowew73ie5wGr8DfJ0gd88EafQ=',
  },
  {
    id: 6,
    country: 'Singapore',
    location: 'Singapore',
    flag: '🇸🇬',
    img:
      'https://images.unsplash.com/flagged/photo-1562503542-2a1e6f03b16b?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8c2luZ2Fwb3JlJTIwc2t5bGluZXxlbnwwfHwwfHx8MA%3D%3D', // Singapore
  },
  {
    id: 7,
    country: 'Thailand',
    location: 'Phuket',
    flag: '🇹🇭',
    img:
      'https://www.travelandleisure.com/thmb/RQ3JmT8V2y2fhwr7NY0cgUumcCE=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/phuket-thailand-karst-formation-phuket0327-92bd3ce9266148dba74cba5e36c711e2.jpg', // Thailand
  },
  {
    id: 8,
    country: 'United Arab Emirates',
    location: 'Dubai',
    flag: '🇦🇪',
    img:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/c/cc/Dubai_Skylines_at_night_%28Pexels_3787839%29.jpg/640px-Dubai_Skylines_at_night_%28Pexels_3787839%29.jpg', // UAE
  },
  {
    id: 9,
    country: 'Netherlands',
    location: 'Amsterdam',
    flag: '🇳🇱',
    img:
      'https://www.holland.com/upload_mm/2/4/4/80160_fullimage_rondvaartboot%20vaart%20onder%20brug%20door%20met%20mooie%20wolkenlucht%20%C2%A9%20illusion-x%20via%20pixabay_1150x663_438x353.jpg', // Netherlands
  },
];

export default travelData;
