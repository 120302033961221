import styled from 'styled-components';

// Assuming this is the background style from the Contact page
const SharedBackground = styled.div`
  background-color: #02aab0;
  background-image: linear-gradient(135deg, #02aab0 0%, #00cdac 100%);
  color: #fff;
  border-top: 0px;
  clip-path: polygon(0 20%, 100% 0, 100% 80%, 0 100%);

  @media (max-width: 768px) {
    clip-path: none; /* Remove clip path for mobile devices */
  }
`;

export const Subtitle = styled.p`
  text-align: center;
  color: #fff;
  font-size: 2.5rem;
  margin: 1rem 0;
`;

export const MainContainer = styled(SharedBackground)`
  width: 100%;
  height: 98rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -19em;

  @media (max-width: 768px) {
    height: 100rem;
    padding: 3rem 0; /* Add padding for mobile devices */
    margin-top: 0; /* Remove negative margin for mobile devices */
    padding: 3rem 0px;
    margin-top: 0px;
    display: flex;
    justify-content: start;
  }
`;

export const Container = styled.div`
  width: 60%;
  height: 40rem;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: ${({ itemsArray }) => `${itemsArray.join('fr ')}fr`};
  grid-gap: 10px;
  transition: 0.5s ease;

  @media (max-width: 768px) {
    grid-template-rows: ${({ itemsArray }) => `${itemsArray.join('fr ')}fr`};
    grid-template-columns: 1fr;
    overflow: auto;
    width: 90%;
    height: 100rem;
  }
`;

export const ItemDiv = styled.div`
  position: relative;
  &:hover .bg {
    opacity: 80%;
  }
  &:hover .text {
    opacity: 100%;
  }
`;

export const Item = styled.img`
  width: 100%;
  height: 100%;
  place-self: center;
  object-fit: cover;
  transition: 0.5s ease;
  cursor: pointer;
  border-radius: 30px;
`;

export const Bg = styled.div`
  background-color: black;
  width: 100%;
  height: 30%;
  background: linear-gradient(
    0deg,
    rgba(10, 10, 10, 1) 0%,
    rgba(10, 10, 10, 0.9066001400560224) 20%,
    rgba(255, 255, 255, 0) 100%
  );
  opacity: 0%;
  position: absolute;
  bottom: 0;
  border-radius: 0 0 30px 30px;
  transition: 0.5s ease;
  cursor: pointer;
`;

export const Text = styled.div`
  cursor: pointer;
  opacity: 0;
  transition: 0.5s ease;
  color: white;
  position: absolute;
`;

export const Header = styled(Text)`
  margin-left: 0px;
  bottom: 42px;
  font-size: 3rem;
  width: 100%;
  text-align: center;
  font-family: Lora, sans-serif;
`;

export const Subheading = styled(Text)`
  margin-left: 0px;
  bottom: 30px;
  font-size: 1.5rem;
  width: 100%;
  text-align: center;
  font-family: Lato, sans-serif;
`;
