class Star {
  constructor(x, y, z, size, speed, canvas) {
    this.x = x;
    this.y = y;
    this.z = z;
    this.size = size;
    this.speed = speed;
    this.canvas = canvas;
  }

  update() {
    this.z -= this.speed;
    if (this.z <= 0) {
      this.reset();
    }
  }

  reset() {
    this.z = this.canvas.width;
    this.x = Math.random() * (this.canvas.width * 2) - this.canvas.width;
    this.y = Math.random() * (this.canvas.height * 2) - this.canvas.height;
    this.size = Math.random() * 2 + 1;
  }

  draw(ctx) {
    const x = ((this.x / this.z) * this.canvas.width) / 2 + this.canvas.width / 2;
    const y = ((this.y / this.z) * this.canvas.height) / 2 + this.canvas.height / 2;
    const radius = (1 - this.z / this.canvas.width) * this.size;
    ctx.beginPath();
    ctx.arc(x, y, radius, 0, Math.PI * 2);
    ctx.fill();
  }
}

export default Star;
