import React, { useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import travelData from './travelData';
import {
  MainContainer,
  Container,
  ItemDiv,
  Item,
  Subtitle,
  Bg,
  Header,
  Subheading,
} from './TravelGallery.styles';
import Title from '../Title/Title';

const TravelGallery = () => {
  const [itemsArray, setItemsArray] = useState([]);

  useEffect(() => {
    // Initialize itemsArray with the length of travelData
    setItemsArray(new Array(travelData.length).fill('1'));
  }, []);

  const handleMouseOver = (id) => {
    const newArray = [...itemsArray];
    newArray[id] = '3';
    setItemsArray(newArray);
  };

  const handleMouseOut = () => {
    // Reset itemsArray to its initial state
    setItemsArray(new Array(travelData.length).fill('1'));
  };

  return (
    <MainContainer>
      <div>
        <Title title="Travel Gallery" />
        <Subtitle>
          <Fade bottom duration={1000} delay={800} distance="30px">
            Exploring Nature&apos;s Masterpieces - My Journey So Far
          </Fade>
        </Subtitle>
      </div>
      <Container itemsArray={itemsArray}>
        {travelData.map((item) => (
          <ItemDiv
            key={item.id}
            onMouseOver={() => handleMouseOver(item.id)}
            onMouseOut={handleMouseOut}
          >
            <Item id={item.id} draggable="false" src={item.img} alt={item.country} />
            <Bg id={item.id} className="bg" />
            <Header className="text header" id={item.id}>
              {item.country}
            </Header>
            <Subheading className="text subheading" id={item.id}>
              {item.location}
            </Subheading>
          </ItemDiv>
        ))}
      </Container>
    </MainContainer>
  );
};

export default TravelGallery;
