import React, { useEffect, useRef } from 'react';
import Star from './Star';

const Starfield = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;
    const numStars = 800;
    const speed = 5;
    let stars = [];

    const setCanvasSize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    const initStars = () => {
      stars = Array.from(
        { length: numStars },
        () =>
          new Star(
            Math.random() * canvas.width,
            Math.random() * canvas.height,
            Math.random() * canvas.width,
            Math.random() * 2 + 1,
            speed,
            canvas
          )
      );
    };

    const updateStars = () => {
      stars.forEach((star) => star.update());
    };

    const drawStars = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = '#FFF';
      stars.forEach((star) => star.draw(ctx));
    };

    const animate = () => {
      updateStars();
      drawStars();
      animationFrameId = requestAnimationFrame(animate);
    };

    const handleResize = () => {
      cancelAnimationFrame(animationFrameId);
      setCanvasSize();
      initStars();
      animate();
    };

    window.addEventListener('resize', handleResize);

    setCanvasSize();
    initStars();
    animate();

    return () => {
      window.removeEventListener('resize', handleResize);
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  return (
    <canvas
      ref={canvasRef}
      id="starfield"
      style={{
        background: 'black',
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
      }}
    />
  );
};

export default Starfield;
